import React, {useEffect, useState} from 'react';
import './pagina-capsulas.css'

const video1 = `${require('../../assets/capsulas/capsula1.mov')}#t=0.001`
const video2 = `${require('../../assets/capsulas/capsula2.mov')}#t=0.001`

export default function PaginaCapsulas() {

    const [width, setWidth] = useState(window.innerWidth);
    const breakpoint = 768;
    useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);


    return (
        <div className={"pagina-wrapper pagina-capsulas"}>
            <h1>Videos</h1>

            {
                width >= breakpoint ?
                    <div className={"noticia-destacada-card-wrapper"}>
                        <video width="30%" height="auto" controls preload="metadata">
                            <source src={video1} type="video/mp4"/>
                            Su navegador no permite la visualización de videos.
                        </video>
                        <video width="30%" height="auto" controls>
                            <source src={video2} type="video/mp4"/>
                            Su navegador no permite la visualización de videos.
                        </video>
                    </div> :
                    <div className={"noticia-destacada-card-wrapper"}>
                        <video width="100%" height="auto" controls style={{margin: "30px auto"}}>
                            <source src={video1} type="video/mp4"/>
                            Su navegador no permite la visualización de videos.
                        </video>
                        <video width="100%" height="auto" controls preload="metadata">
                            <source src={video2} type="video/mp4"/>
                            Su navegador no permite la visualización de videos.
                        </video>
                    </div>
            }
        </div>
    )
}
