import React from 'react';
import './informacion-proyecto.css'
import logo_gore from "../../assets/financia_ejecuta/logo_gore.png";
import logo_core from "../../assets/financia_ejecuta/logo_core.svg";
import logo_unap from "../../assets/financia_ejecuta/logo_unap.svg";
import {Imagen} from "../../components/imagenes/Imagenes";

export default function InformacionProyecto() {
    return (
        <>
            <div className={"informacion-proyecto"}>
                <div className={"informacion-proyecto-logos"}>
                    <div className={"financia"}>
                        <div className={"texto-financia"}>FINANCIA</div>
                        <div className={"logos-financia"}>
                            <Imagen className={"logo-proyecto"} src={logo_gore} alt={'logo_gore_tarapaca'}
                                    width={"100%"} height={"100%"}/>
                            <Imagen className={"logo-proyecto"} src={logo_core} alt={'logo_core_tarapaca'}
                                    width={"100%"} height={"100%"}/>
                        </div>
                    </div>
                    <div className={"ejecuta"}>
                        <div className={"texto-ejecuta"}>EJECUTA</div>
                        <div className={"logos-ejecuta"}>
                            <Imagen className={"logo-proyecto"} src={logo_unap} alt={'logo_unap'} width={"100%"}
                                    height={"100%"}/>
                        </div>
                    </div>
                </div>
                <div className={"informacion-proyecto-texto"}>
                    Transferencia Red de Mujerezas… Profesionalizando el emprendimiento en la Región Tarapacá. <span>Código BIP: 40058330-0</span>
                </div>
            </div>
            <div className={"informacion-financia-texto"}>Proyecto Financiado por el Gobierno Regional de Tarapacá y su
                Consejo Regional a través del Fondo de Innovación para la Competitividad FIC
            </div>
        </>
    )
}
