import React, {useEffect, useState} from 'react';
import './pagina-emprendedoras.css'
import EmprendedoraCard from "../../components/cards/EmprendedoraCard";
import {BotonEmprendedora} from "../../components/boton/Botones";
import {getRequest} from "../../requests/requests";
import {Imagen} from "../../components/imagenes/Imagenes";
import img_proximamente from "../../assets/emprendedoras/proximamente.png";

export default function PaginaEmprendedoras() {

    const abecedario = ["a - c", "d - f", "g - i", "j - l", "m - ñ", "o - q", "r - t", "u - w", "x - z"]
    const [active, setActive] = useState(0)

    useEffect(() => {
        //return getRequest()
    })

    const handleClick = (event) => {
        setActive(Number(event.target.id))
    }

    return (
        <div className={"pagina-wrapper pagina-emprendedoras"}>
            <h1>PRÓXIMAMENTE</h1>
            <p>Muy pronto podrás conocer el listado de las 40 liderezas de Pymes y empresas de la Región de Tarapacá que
                integrarán el grupo de Red de Mujerezas. En este espacio te contaremos a qué se dedica cada una de
                ellas, cuáles son sus negocios y cómo puedes encontrarlas para comprar sus productos a través de redes
                sociales y/o de forma presencial.
            </p>
            <Imagen className={"imagen-emprendedoras-proximamente"} width={"100%"} src={img_proximamente}></Imagen>
            {/*
            <div className={`flex center`}>
                {abecedario.map((letra, index) => {
                    return (<BotonEmprendedora id={index} key={index} texto={letra} active={index === active} onClick={handleClick}/>)
                })}
            </div>
            <EmprendedoraCard/>
            <EmprendedoraCard/>
            */}
        </div>
    )
}
