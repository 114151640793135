import React, {useCallback, useEffect, useState} from 'react';
import './carrusel-inicio.css'
import img1 from '../../../assets/carrusel_inicio/equipo2_comprimida.png'
import img2 from '../../../assets/carrusel_inicio/carrusel2_texto.jpg'
import {Imagen, LinkImagen} from "../../imagenes/Imagenes";

export function CarruselInicio({className}) {
    const imagenes = [{imagen: img1, to: null}, {imagen: img2, to: "/noticias/1"}]
    const [active, setActive] = useState(0)

    const [startForward, setStartForward] = useState(-1)
    const [endForward, setEndForward] = useState(-1)
    const [nextForward, setNextForward] = useState(-1)

    const [startBack, setStartBack] = useState(-1)
    const [endBack, setEndBack] = useState(-1)
    const [nextBack, setNextBack] = useState(-1)

    const [inTransition, setInTransition] = useState(0)

    const sleep = ms => new Promise(r => setTimeout(r, ms));
    const sleepTime = 5
    const handleNext = useCallback(async (next) => {

        if (document.hasFocus() && !inTransition && imagenes.length > 1) {
            setInTransition(1)
            setNextForward(next)
            await sleep(sleepTime).then(async () => {
                setStartForward(active)
                await sleep(sleepTime)
                setEndForward(next)
                await sleep(sleepTime)
                //transicion
                setActive(next)
                await sleep(700).then(() => {
                    cleanTransitionParams()
                    setInTransition(0)
                })
            })
        }
    }, [active, imagenes.length, inTransition])

    useEffect(() => {
        const timer = setInterval(async () => {
            await handleNext((active + 1) % imagenes.length)
            await sleep(sleepTime)
        }, 5000);
        return () => clearInterval(timer);
    }, [active, handleNext, imagenes.length]);

    function setClass(index, comparacion, className) {
        if (index === comparacion)
            return " " + className
        return ""
    }

    function getNextSlideIndex() {
        return (active + 1) % imagenes.length
    }

    function getPrevSlideIndex() {
        if (active === 0) {
            return imagenes.length - 1
        }
        return (active - 1)
    }

    function cleanTransitionParams() {
        setStartForward(-1)
        setEndForward(-1)
        setNextForward(-1)
        setStartBack(-1)
        setEndBack(-1)
        setNextBack(-1)
    }

    async function handlePrev(prev) {
        if (!inTransition && imagenes.length > 1) {
            setInTransition(1)
            setNextBack(prev)
            await sleep(1)
            setStartBack(active)
            setEndBack(prev)
            setActive(prev)
            await sleep(600)
            cleanTransitionParams()
            setInTransition(0)
        }
    }

    async function handleClick(e, index) {
        if (index > active)
            await handleNext(index)
        if (index < active)
            await handlePrev(index)
    }

    return (
        <div className={className}>
            <div className={"slider"}>
                {imagenes.map((imagen, index) => {
                    if (imagen.to)
                        return <LinkImagen key={index}
                                           to={imagen.to}
                                           className={`carrusel-imagen
                                ${setClass(index, active, 'active')}
                                ${setClass((index), nextForward, 'carrusel-inicio-next-forward')}
                                ${setClass(index, startForward, 'carrusel-inicio-start-forward')}
                                ${setClass(index, endForward, 'carrusel-inicio-end-forward')}
                                ${setClass((index), nextBack, 'carrusel-inicio-next-back')}
                                ${setClass(index, startBack, 'carrusel-inicio-start-back')}
                                ${setClass(index, endBack, 'carrusel-inicio-end-back')}`}
                                           width={"100%"} height={"100%"} src={imagen.imagen}
                                           />
                    else
                        return <Imagen key={index}
                                       className={`carrusel-imagen
                                ${setClass(index, active, 'active')}
                                ${setClass((index), nextForward, 'carrusel-inicio-next-forward')}
                                ${setClass(index, startForward, 'carrusel-inicio-start-forward')}
                                ${setClass(index, endForward, 'carrusel-inicio-end-forward')}
                                ${setClass((index), nextBack, 'carrusel-inicio-next-back')}
                                ${setClass(index, startBack, 'carrusel-inicio-start-back')}
                                ${setClass(index, endBack, 'carrusel-inicio-end-back')}`}
                                       width={"100%"} height={"100%"} src={imagen.imagen}
                                       />

                })}
            </div>
            <div className={"control-circles"}>
                {imagenes.map((_, index) => {
                    return (
                        <span key={index}
                              className={`control-circle ${index === active ? "control-circle-active" : ""}`}
                              onClick={(e) => handleClick(e, index)}/>
                    )
                })}
            </div>
            <button className="control-arrow-prev" onClick={() => handlePrev(getPrevSlideIndex())}>❮</button>
            <button className="control-arrox-next" onClick={() => handleNext(getNextSlideIndex())}>❯</button>

        </div>
    )
}
