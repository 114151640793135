import React from 'react';
import './pagina-noticia-detalle.css'
import {Imagen} from "../../components/imagenes/Imagenes";
import imagen_noticia from "../../assets/noticias/4.jpeg";

export default function PaginaNoticiaDetalle() {
    return (
        <div className={"pagina-wrapper"}>
            <div className={"noticia-detalle"}>
                <h1>
                    “RED DE MUJEREZAS” UNAP REALIZÓ FOCUS GROUP PARA IDENTIFICAR
                    LAS PRINCIPALES BRECHAS DE LAS EMPRENDEDORAS TARAPAQUEÑAS
                </h1>
                <Imagen className={"noticia-imagen"} src={imagen_noticia} width={"100%"}/>
                <div className={"noticia-bajada"} style={{color: "black"}}>
                    <ul>
                        <li><p>En los encuentros participaron actores claves del ecosistema de emprendimiento
                            regional.</p>
                        </li>
                        <li><p>Los resultados permitirán diseñar herramientas y estrategias claves para disminuir
                            dichas brechas y profesionalizar sus negocios.</p>
                        </li>
                    </ul>

                </div>

                <div className={"noticia-cuerpo"} style={{color: "black"}}>
                    <p>
                        <time className={"noticia-fecha"}>Iquique, martes 30 de julio</time>
                        . Durante el mes de julio el equipo de “Red de Mujerezas…
                        Profesionalizando el Emprendimiento en la Región de Tarapacá”, un proyecto financiado
                        por el Gobierno Regional de Tarapacá, a través del Fondo de Innovación para la
                        Competitividad (FIC) 2023, y desarrollado por la UNAP, realizó dos Focus Group para
                        identificar las principales brechas con las que se topan las mujeres empresarias y
                        emprendedoras de la Región de Tarapacá.
                    </p>
                    <p>En los grupos focales participaron representantes y autoridades de servicios públicos y de
                        aceleradoras de negocios provenientes de: la Seremi de la Mujer y la Equidad de Género,
                        Fosis, Prodemu, Conadi, Oficina de Fomento y Productividad de la Municipalidad de Alto
                        Hospicio, OFIIT UNAP, Circulartec, Tara-paka, Inncuba GT, Gedes y Mujeres Non Stop,
                        entre otros.</p>
                    <p>El director general de Innovación y director del Proyecto “Red de Mujerezas”, Raúl Castillo
                        Villagra explicó que: “El propósito principal fue comprender las percepciones y
                        necesidades de las mujeres en el ecosistema tarapaqueño. Los datos destacaron la
                        importancia de las redes de apoyo en capacitación y soporte, así como fortalezas como la
                        resiliencia e innovación y desafíos como el acceso a financiamiento. Esta información será
                        esencial para diseñar soluciones efectivas y programas más eficientes que fortalezcan a las
                        mujeres emprendedoras en Tarapacá”.</p>
                    <p>Hasta ahora, el equipo del proyecto ha logrado identificar dos tipos de mujeres
                        emprendedoras: las profesionales y las dueñas de casa y/o cuidadoras. Las primeras
                        regularmente son más jóvenes y creativas, y sus negocios buscan dar solución a un
                        problema; mientras que las segundas emprenden principalmente por necesidad y/o, por
                        incrementar sus recursos.</p>
                    <p>La información recolectada en los focus permitirá determinar cuáles son las brechas que
                        enfrentan las mujeres emprendedoras de Tarapacá y cómo articular este ecosistema de
                        emprendimiento con el mundo académico de la UNAP con el fin de aportar a la
                        disminución de dichas brechas y en la profesionalización de los negocios liderados por
                        mujeres en la región.</p>

                </div>
            </div>
        </div>
    )
}
