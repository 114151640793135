import React from 'react';
import './pagina-noticias.css'
import NoticiaCard from "../../components/cards/NoticiaCard";

export default function PaginaNoticias(){
    return (
        <div className={"pagina-wrapper pagina-noticias"}>
            <h1>Noticias</h1>
            <NoticiaCard/>
        </div>
    )
}
