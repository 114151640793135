import React, {useEffect, useState} from 'react';
import './noticia-card.css'
import {Imagen} from "../imagenes/Imagenes";
import imagen_noticia from "../../assets/noticias/4.jpeg"
import {NavLink} from "react-router-dom";
import video1  from "../../assets/capsulas/capsula1.mov"

export function HorizontalCard() {
    return (
        <div className={"card-wrapper"}>
            <Imagen className={"card-imagen"} src={imagen_noticia} width={"100%"}/>
            <div className={"card-contenido"}>
                <h2 className={"horizontal-card-titulo"}>
                    “RED DE MUJEREZAS” UNAP REALIZÓ FOCUS GROUP PARA IDENTIFICAR
                    LAS PRINCIPALES BRECHAS DE LAS EMPRENDEDORAS TARAPAQUEÑAS
                </h2>
                <div className={"card-texto"}>
                    <ul>
                        <li><p>En los encuentros participaron actores claves del ecosistema de emprendimiento
                            regional.</p>
                        </li>
                        <li><p>Los resultados permitirán diseñar herramientas y estrategias claves para disminuir
                            dichas brechas y profesionalizar sus negocios.</p>
                        </li>
                    </ul>
                </div>
                <button className={"boton-ver-mas"} style={{margin: "0 auto"}}>
                    <NavLink to={"1"}>
                        Ver más
                    </NavLink>
                </button>
            </div>
        </div>
    )
}

export function VerticalCard() {
    return (
        <div className={"card-wrapper"}>
            <div className={"card-contenido"}>
                <h2 className={"horizontal-card-titulo"}>
                    “RED DE MUJEREZAS” UNAP REALIZÓ FOCUS GROUP PARA IDENTIFICAR
                    LAS PRINCIPALES BRECHAS DE LAS EMPRENDEDORAS TARAPAQUEÑAS
                </h2>
                <Imagen className={"card-imagen"} src={imagen_noticia} width={"100%"}/>
                <div className={"card-texto"}>
                    <ul>
                        <li><p>En los encuentros participaron actores claves del ecosistema de emprendimiento
                            regional.</p>
                        </li>
                        <li><p>Los resultados permitirán diseñar herramientas y estrategias claves para disminuir
                            dichas brechas y profesionalizar sus negocios.</p>
                        </li>
                    </ul>
                </div>
                <button className={"boton-ver-mas"}>
                    <NavLink to={"1"}>
                        Ver más
                    </NavLink>
                </button>
            </div>
        </div>
    )
}

export function NoticiaDestcadaCard() {
    return (
        <div className={"card-wrapper"}>
            <div className={"card-contenido"}>
                <Imagen className={"card-imagen"} src={imagen_noticia} width={"100%"}/>
                <h2 className={"horizontal-card-titulo"}>
                    “RED DE MUJEREZAS” UNAP REALIZÓ FOCUS GROUP PARA IDENTIFICAR
                    LAS PRINCIPALES BRECHAS DE LAS EMPRENDEDORAS TARAPAQUEÑAS
                </h2>
                <div className={"card-texto"}>
                    <ul>
                        <li><p>En los encuentros participaron actores claves del ecosistema de emprendimiento
                            regional.</p>
                        </li>
                        <li><p>Los resultados permitirán diseñar herramientas y estrategias claves para disminuir
                            dichas brechas y profesionalizar sus negocios.</p>
                        </li>
                    </ul>
                </div>
                <button className={"boton-ver-mas"}>
                    <NavLink to={"noticias/1"}>
                        Ver más
                    </NavLink>
                </button>
            </div>
        </div>
    )
}

export function VideoCard() {
    return (
        <div className={"card-wrapper"}>
            <div className={"card-contenido"}>
                <video width="320" height="240" controls>
                    <source src={video1} type="video/mp4">
                            Your browser does not support the video tag.
                    </source>
                </video>
                <h2 className={"horizontal-card-titulo"}>
                </h2>
                <div className={"card-texto"}>
                </div>
            </div>
        </div>
)
}


export default function NoticiaCard() {
    const [width, setWidth] = useState(window.innerWidth);
    const breakpoint = 768;
    useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return <>
        {
            width >= breakpoint ?
                <HorizontalCard/> : <VerticalCard/>
        }
    </>
}
