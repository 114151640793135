import './App.css';
import {Route, Routes} from 'react-router-dom';
import Header from "./header/Header";
import PaginaInicio from "./paginas/inicio/PaginaInicio";
import Footer from "./footer/Footer";
import PaginaEquipo from "./paginas/equipo/PaginaEquipo";
import PaginaEmprendedoras from "./paginas/emprendedoras/PaginaEmprendedoras";
import PaginaNoticias from "./paginas/noticias/PaginaNoticias";
import PaginaCapsulas from "./paginas/capsulas/PaginaCapsulas";
import PaginaProximamente from "./paginas/proximamente/PaginaProximamente";
import PaginaNoticiaDetalle from "./paginas/noticias/PaginaNoticiaDetalle";


function App() {
    return (
        <>
            <Header/>
            <div className={"content-wrapper"}>
                <Routes>
                    <Route path="" element={<PaginaInicio/>}/>
                    <Route path="equipo" element={<PaginaEquipo/>}/>
                    <Route path="emprendedoras" element={<PaginaEmprendedoras/>}/>
                    <Route path="noticias" element={<PaginaNoticias/>}/>
                    <Route path="noticias/1" element={<PaginaNoticiaDetalle/>}/>
                    <Route path="capsulas" element={<PaginaCapsulas/>}/>
                    <Route path="proximamente" element={<PaginaProximamente/>}/>
                </Routes>
            </div>
            <Footer/>
        </>
    );
}

export default App;
